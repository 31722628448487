<template>
    <div  class="confirm">
        <h2>申请返现</h2>
        <div style="margin-top: 15px;">
            <el-input placeholder="请输入支付宝的登录账号" v-model="alipayLoginId">
                <template slot="prepend">账号</template>
            </el-input>
        </div>
        <div style="margin-top: 15px;">
            <el-input placeholder="请输入支付宝的真实姓名" v-model="alipayName">
                <template slot="prepend">姓名</template>
            </el-input>
        </div>
        <div style="margin-top: 15px;">
            <el-input placeholder="请输入手机号码" v-model="phone">
                <template slot="prepend">手机</template>
            </el-input>
        </div>
        <div @click="giveMeMoney" class="submit">
            确认申请
        </div>
        <p>申请提交等待管理员审核通过后，届时请注意查收微信或支付宝账单</p>
    </div>
    
</template>

<script>

export default {
    data() {
        return {
            alipayLoginId: '',
            alipayName: '',
            phone: ''
        }
    },
    methods:{
        giveMeMoney(){
            const params = {
                alipayLoginId: this.alipayLoginId,
                alipayName: this.alipayName,
                phone: this.phone
            }

            this.$http.post("/api/wechat/applyCashBack", params).then(res => {
                console.log(res)

                if(res.data.status.errCode === 200){
                    this.$router.push({'path':'/result'})
                }
            })
        }
    }
}
</script>

<style>

</style>